// extracted by mini-css-extract-plugin
export var productBox = "product-page-module--productBox--futuv";
export var container = "product-page-module--container--EREiA";
export var header = "product-page-module--header--ZAnqx";
export var productDescription = "product-page-module--productDescription--GKu-y";
export var productImageWrapper = "product-page-module--productImageWrapper--++InC";
export var productImageList = "product-page-module--productImageList--6cRAn";
export var productImageListItem = "product-page-module--productImageListItem--P3+RE";
export var scrollForMore = "product-page-module--scrollForMore--Wcc95";
export var noImagePreview = "product-page-module--noImagePreview--uzW3V";
export var priceValue = "product-page-module--priceValue--gk0OJ";
export var visuallyHidden = "product-page-module--visuallyHidden--Px8+B";
export var optionsWrapper = "product-page-module--optionsWrapper--jH9zF";
export var addToCartStyle = "product-page-module--addToCartStyle--r5Cxs";
export var selectVariant = "product-page-module--selectVariant--m-gJw";
export var labelFont = "product-page-module--labelFont--nMPhI";
export var tagList = "product-page-module--tagList--I5sOk";
export var breadcrumb = "product-page-module--breadcrumb--TUr7s";
export var metaSection = "product-page-module--metaSection--lSCM7";